/* Estilos generales para el contenedor de documentos */
.contenedor-investors {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 100%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Barra de búsqueda */
  .search-bar {
    margin-bottom: 20px;
    padding: 8px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Estilos para el contenedor de la tabla con scroll horizontal */
  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Mejora la experiencia de desplazamiento en iOS */
  }
  
  /* Estilos para la tabla de documentos */
  .document-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px; /* Asegura que la tabla tenga un ancho mínimo para que el scroll se active */
  }
  
  /* Estilos para las celdas de la tabla */
  .document-table th, .document-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-wrap: break-word; /* Asegura que el texto largo se ajuste */
  }
  
  /* Estilo para los encabezados de la tabla */
  .document-table th {
    background-color: #f2f2f2;
  }
  
  /* Estilo para los enlaces de descarga */
  .download-link {
    color: #fbad18;
    text-decoration: underline;
    cursor: pointer;
  }
  
/* Estilos para la paginación */
.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente los botones */
  }
  
  .pagination button {
    margin: 0 5px; /* Espaciado entre los botones */
    padding: 10px 15px; /* Espaciado interno */
    border: none; /* Sin bordes */
    border-radius: 5px; /* Bordes redondeados */
    background-color: #f0f0f0; /* Fondo gris claro */
    color: #333; /* Color del texto */
    cursor: pointer; /* Cambia el cursor al pasar el ratón */
    transition: background-color 0.3s; /* Transición suave para el fondo */
  }
  
  .pagination button:hover {
    background-color: #007bff; /* Fondo azul al pasar el ratón */
    color: white; /* Texto blanco al pasar el ratón */
  }
  
  .pagination button.active {
    background-color: #007bff; /* Fondo azul para la página activa */
    color: white; /* Texto blanco para la página activa */
    font-weight: bold; /* Texto en negrita para la página activa */
  }
  
  .pagination button:disabled {
    background-color: #ddd; /* Fondo gris para botones deshabilitados */
    color: #aaa; /* Texto gris para botones deshabilitados */
    cursor: not-allowed; /* Cursor de no permitido */
  }
  